#__next, [data-rk] {
    min-height: 100vh;
}

.sign-in-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.signin-w-metamask-btn {
    cursor: pointer;
    background-color: var(--colors-primary9);
    color: white;
    border-radius: 8px;
    padding-left: var(--space-5);
    padding-right: var(--space-5);
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
    min-height: 44px;
    outline: none;
    font-weight: 700;
    font-size: 16px;
    font-family: var(--fonts-button);
    transition: background-color 250ms linear 0s;
    gap: var(--space-2);
    display: inline-flex;
    align-items: center;
    line-height: 20px;
    ---focusColor: var(--colors-gray12);
}

.signin-w-metamask-btn:disabled {
    background-color: var(--colors-gray6);
    cursor: not-allowed;
}

.signin-w-metamask-btn:not(:disabled):hover {
    background-color: var(--colors-primary10);
}
